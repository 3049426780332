const { TrueorFalse } = require("../dataValidations")

function TriggerValidation (rows) {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^hierarchyId$/.test(row[0])){
    return "Column 1 heading should be hierarchyId"
  } else if (!row[1] || !/^triggerId$/.test(row[1])){
    return "Column 2 heading should be triggerId"
  } else if (!row[2] || !/^defaultTemplateId$/.test(row[2])){
    return "Column 3 heading should be defaultTemplateId"
  } else if (!row[3] || !/^templateIds$/.test(row[3])){
    return "Column 4 heading should be templateIds"
  } else if (!row[4] || !/^cronTime$/.test(row[4])){
    return "Column 5 heading should be cronTime"
  } else if (!row[5] || !/^eventName$/.test(row[5])){
    return "Column 6 heading should be eventName"
  } else if (!row[6] || !/^functionName$/.test(row[6])){
    return "Column 7 heading should be functionName"
  } else if (!row[7] || !/^mandatory$/.test(row[7])){
    return "Column 8 heading should be mandatory"
  } else if (!row[8] || !/^entityTimeRelated$/.test(row[8])){
    return "Column 9 heading should be entityTimeRelated"
  } else if (!row[9] || !/^isNewTemplate$/.test(row[9])){
    return "Column 10 heading should be isNewTemplate"
  } else if (!row[10] || !/^content$/.test(row[10])){
    return "Column 11 heading should be content"
  } else if (!row[11] || !/^languageId$/.test(row[11])){
    return "Column 12 heading should be languageId"
  } else if (!row[12] || !/^typeId$/.test(row[12])){
    return "Column 13 heading should be typeId"
  } else if (!row[13] || !/^parameters$/.test(row[13])){
    return "Column 14 heading should be parameters"
  } else if (!row[14] || !/^unicode$/.test(row[14])){
    return "Column 15 heading should be unicode"
  } else if (!row[15] || !/^clientId$/.test(row[15])){
    return "Column 16 heading should be clientId"
  } else if (!row[16] || !/^vendorId$/.test(row[16])){
    return "Column 17 heading should be vendorId"
  } else if (!row[17] || !/^isActive$/.test(row[17])){
    return "Column 18 heading should be isActive"
  } else if (!row[18] || !/^module$/.test(row[18])){
    return "Column 19 heading should be module"
  } else if (!row[19] || !/^notificationType$/.test(row[19])){
    return "Column 20 heading should be notificationType"
  }
}
function validateCsvRow (row) {
  if (
    /[,]/g.test(row[0]) ||
      /[,]/g.test(row[1])
  ) {
    return "Remove every single comma ',' and try again"
  } else if (!row[0] || !/^[0-9]+$/.test(row[0])) {
    return `Column 1 should have a valid hierarchy Id`
  } else if (!row[1] || !/^[0-9]*$/.test(row[1])) {
    return `Column 2 should have a valid triggerId`
  } else if (!row[2] || !/^[0-9]+$/.test(row[2])) {
    return `Column 3 should have a valid defaultTemplateId`
  } else if (!(/^[0-9, ]*$/.test(row[3]) || /^$/gm.test(row[3]))) {
    return `Column 4 should have a valid templateIds`
  } else if (!row[4] || !/^[0-9* ]+$/.test(row[4])) {
    return `Column 5 should have a valid cronTime`
  } else if (!row[5] || !/^[A-Za-z-]*$/.test(row[5])) {
    return `Column 6 should have a valid eventName`
  } else if (!row[6] || !/^[A-Za-z]+$/.test(row[6])) {
    return `Column 7 should have a valid functionName`
  } else if (!row[7] || !TrueorFalse(row[7])) {
    return `Column 8 should have a valid mandatory`
  } else if (!row[8] || !TrueorFalse(row[8])) {
    return `Column 9 should have a valid entityTimeRelated`
  } else if (!row[9] || !TrueorFalse(row[9])) {
    return `Column 10 should have a valid isNewTemplate`
  } else if (!(/^[0-9]*$/.test(row[11]) || /^$/gm.test(row[11]))) {
    return `Column 12 should have a valid languageId`
  } else if (!(/^[0-9]+$/.test(row[12]) || /^$/gm.test(row[12]))) {
    return `Column 13 should have a valid typeId`
  } else if (!(/^[a-zA-Z0-9]+$/.test(row[13]) || /^$/gm.test(row[13]))) {
    return `Column 14 should have a valid parameters`
  } else if (!(TrueorFalse(row[14]) || /^$/gm.test(row[14]))) {
    return `Column 15 should have a valid unicode`
  }
}
module.exports = TriggerValidation
